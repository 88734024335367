<template>
  <div class="header">
    <div class="headerLogo">
      <img src="../assets/images/logo-mini.png" alt="logo" />
      精品课程教学资源库
    </div>
    <div class="btnLogin" @click="onBtnLoginClicked">{{ strBtnLogin }}</div>
  </div>
</template>

<script setup>
  import {ref, onMounted} from "vue";

  let strBtnLogin = ref("登  录");
  
  onMounted(()=>{
    const loginToken = localStorage.getItem('token') || '';
    if(loginToken){
      strBtnLogin.value = "注  销"
    }
  })

  function onBtnLoginClicked(){
    // 清除缓存中的token
    localStorage.removeItem('token')
    strBtnLogin.value = "登  录"
    window.location.reload()
  }
</script>

<style scoped lang="scss">
  @import '../assets/variables.scss';
  .header{
    background-color: $primary-color;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    color: white;
  }
  .headerLogo{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
  }
  .headerLogo img{
    width: 30px;
    margin-right: 14px;
  }
  .btnLogin{
    font-size: 0.8rem;
    border: 1px solid white;
    padding: 4px 12px;
    margin-right: 20px;
    border-radius: 4px;
  }
</style>