<template>
  <div class="footer">
    <img class="imgLogo" src="../assets/images/logo.png" alt="logo" />
    <div class="info">
      <p>学校地址：广州市天河区大观中路492号</p>
      <p>招生热线：020-22305678</p>
    </div>
  </div>
</template>

<style scoped lang="scss">
  @import '../assets/variables.scss';
  .footer{
    background-color: $primary-color;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
  }
  .imgLogo{
    width: 10vw;
    min-width: 80px;
  }
  .info{
    color: white;
    margin: 10px 30px;
    font-size: 0.8rem;
    text-align: left;
    line-height: 1.4rem;
  }
</style>