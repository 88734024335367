<template>
 <router-view />
</template>

<style lang="scss">
@import './assets/variables.scss';
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a{
  text-decoration: none;
  color: #333;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #f2f2f2;
}
.myPage {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.myPageContent{
  flex-grow: 1;
}
.subTitle{
  color: $primary-color;
  padding: 6px 0;
  border-bottom: 1px solid #ccc;
  margin-bottom: 6px;
}
</style>
